import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"

import footerStyles from "./footer.module.css"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      girl: file(relativePath: { eq: "footer/girl.png" }) {
        childImageSharp {
          fixed(width: 162) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      divider: file(relativePath: { eq: "footer/divider.png" }) {
        childImageSharp {
          fixed(height: 235) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      twitter: file(relativePath: { eq: "footer/logos/twitter.png" }) {
        childImageSharp {
          fixed(height: 38, width: 38) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      facebook: file(relativePath: { eq: "footer/logos/facebook.png" }) {
        childImageSharp {
          fixed(height: 38, width: 38) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      instagram: file(relativePath: { eq: "footer/logos/instagram.png" }) {
        childImageSharp {
          fixed(height: 38, width: 38) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      pinterest: file(relativePath: { eq: "footer/logos/pinterest.png" }) {
        childImageSharp {
          fixed(height: 38, width: 38) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      youtube: file(relativePath: { eq: "footer/logos/youtube.png" }) {
        childImageSharp {
          fixed(height: 38, width: 38) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <footer className={footerStyles.footer}>
      <div className={footerStyles.banner}>
        <div className="container">
          <div className="row">
            <div className="col-sm">
              <div><Img alt="flower divider" fixed={data.divider.childImageSharp.fixed} /></div>
              <strong>Upcoming Events</strong>
              <div>
                <small>Day Old Chicks Friday (All Year Round)</small>
                <br />
                <small>Pumpkins (Fall)</small>
              </div>
            </div>
            <div className="col-sm">
              <div><Img alt="flower divider" fixed={data.divider.childImageSharp.fixed} /></div>
              <strong>Hours and Information</strong>
              <div>
                <strong><small>Closed on Sunday and Monday.</small></strong>
                <br />
                <small><strong>POULTRY AND TOURS</strong> (By Appointment Only)</small>
                <br />
                <small>10am-4pm, Tuesday-Saturday</small>
                <br />
                <small>(If over 70°, 10am-12pm)</small>
                <br />
                <small><strong>FARM STORE (Feed & Supplies):</strong></small>
                <br />
                <small>10am-5pm, Tuesday-Saturday</small>
                <br />
                <small>(No Appointment Necessary)</small>
                <br />
                <small><strong>Tours Available by Appointment:</strong></small>
                <br />
                <small>$8/per person - $24 minimum</small>
              </div>
            </div>
            <div className="col-sm">
              <div><Img alt="flower divider" fixed={data.divider.childImageSharp.fixed} /></div>
              <strong>Contact Us</strong>
              <div>
                <small>Please call or text during hours!</small>
                <br/>
                <small><strong>Phone: </strong>425-765-7883</small>
                <br/>
                <small><strong>Email: </strong><a className={footerStyles.link} href="mailto:baxterbarn@hotmail.com" target="_blank" rel="noopener noreferrer">baxterbarn@hotmail.com</a></small>
                <br/>
                <small><strong>Address:</strong></small>
                <br/>
                <a className={footerStyles.link} href="https://maps.google.com/maps?q=31929+SE+44th+ST+Fall+City+WA+98024&hl=en&ll=47.564814,-121.915069&spn=0.009093,0.022724&sll=47.272986,-120.882277&sspn=4.681624,11.634521&t=h&hnear=31929+SE+44th+St,+Fall+City,+Washington+98024&z=16&iwloc=A" rel="noopener noreferrer">
                  <small>31929 SE 44th ST</small>
                  <br/>
                  <small>Fall City WA 98024</small>
                </a>
                <br/>
                <small>© Baxter Barn {new Date().getFullYear()}</small>
              </div>
              <div className={footerStyles.logos}>
                <a href="https://www.twitter.com/BaxterBarn/" target="_blank" rel="noopener noreferrer"><Img alt="twitter logo" fixed={data.twitter.childImageSharp.fixed}/></a>
                <a href="https://www.pinterest.com/baxter_barn/" target="_blank" rel="noopener noreferrer"><Img alt="pinterest logo" fixed={data.pinterest.childImageSharp.fixed}/></a>
                <a href="https://www.facebook.com/BaxterBarn" target="_blank" rel="noopener noreferrer"><Img alt="facebook logo" fixed={data.facebook.childImageSharp.fixed}/></a>
                <a href="http://youtube.com/user/BaxterBarnorg" target="_blank" rel="noopener noreferrer"><Img alt="youtube logo" fixed={data.youtube.childImageSharp.fixed}/></a>
                <a href="https://www.instagram.com/baxter_barn/" target="_blank" rel="noopener noreferrer"><Img alt="instagram logo" fixed={data.instagram.childImageSharp.fixed}/></a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <small><strong>Website Maintained by Pratik Vaidya</strong></small>
        </div>
        <Img className={footerStyles.girl} alt="girl holding sign" fixed={data.girl.childImageSharp.fixed} />
      </div>
    </footer>
  )
}

export default Footer