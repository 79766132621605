import PropTypes from "prop-types"
import React from "react"
import navItemStyles from "./navItem.module.css"

export default class NavItem extends React.Component {
  render() {
    return (
      <a className={navItemStyles.link} href={this.props.link}>
        <div className={navItemStyles.container}>
          <p className={navItemStyles.text}>{this.props.text}</p>
        </div>
      </a>
    )
  }
}

NavItem.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
};

NavItem.defaultProps = {
  text: `Link`,
  link: `#`,
};