import PropTypes from "prop-types"
import React from "react"
import navLinkStyles from "./navLink.module.css"

const NavLink = ({text, link, onEnter, onLeave}) => (
  <a href={link}>
    <div className={navLinkStyles.container}>
        <p className={navLinkStyles.text}>{text}</p>
    </div>
  </a>
)

NavLink.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
}

NavLink.defaultProps = {
  text: `Link`,
  link: `#`,
}

export default NavLink
