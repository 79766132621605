import React from "react"
import mobileNavStyles from "./mobileNav.module.css"

import NavMenu from "./navMenu"
import NavItem from "./navItem"

const MobileNav = () => (
  <div className={mobileNavStyles.container}>
    <NavMenu text="Menu">
      <NavItem text="About Us" link="/about-us"></NavItem>
      <NavItem text="Chicks to Fully Mature" link="/farm-business/chicks-to-fully-mature"></NavItem>
      <NavItem text="Pet Products" link="/farm-business/pet-products"></NavItem>
      <NavItem text="Pumpkins" link="/farm-business/pumpkins"></NavItem>
      <NavItem text="Feed / Farm Supplies" link="/farm-business/feed-farm-supplies"></NavItem>
      <NavItem text="Online Products" link="/farm-business/online-products"></NavItem>
      <NavItem text="Contact Us" link="/contact-us"></NavItem>
    </NavMenu>
  </div>
)

export default MobileNav
