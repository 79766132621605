import React from "react"
import headerNavStyles from "./headerNav.module.css"

import NavLink from "./navLink"
import NavMenu from "./navMenu"
import NavItem from "./navItem"

const BottomHeaderNav = () => (
  <div className={headerNavStyles.container} style={{paddingLeft: `80px`, paddingRight: `80px`, borderRadius: `0 0 1em 1em`}}>
      <NavLink text="Home" link="/"></NavLink>
      <NavLink text="About Us" link="/about-us"></NavLink>
      <NavMenu text="Farm Business">
        <NavItem text="Bulk / COF / Compost" link="/farm-business/bulk-cof-compost"></NavItem>
        <NavItem text="Bees and Honey" link="/farm-business/bees-and-honey"></NavItem>
        <NavItem text="Chicks to Fully Mature" link="/farm-business/chicks-to-fully-mature"></NavItem>
        {/* <NavItem text="Pet Products" link="/farm-business/pet-products"></NavItem> */}
        <NavItem text="Donkeys" link="/farm-business/donkeys"></NavItem>
        <NavItem text="Feed / Farm Supplies" link="/farm-business/feed-farm-supplies"></NavItem>
        <NavItem text="Fresh Eggs" link="/farm-business/fresh-eggs"></NavItem>
        {/* <NavItem text="Online Products" link="/farm-business/online-products"></NavItem> */}
        <NavItem text="Pumpkins" link="/farm-business/pumpkins"></NavItem>
      </NavMenu>
      <NavMenu text="Visit Us">
        <NavItem text="Private Farm Tours" link="/visit-us/private-farm-tours"></NavItem>
        <NavItem text="Educational Programs" link="/visit-us/educational-programs"></NavItem>
        <NavItem text="Hands on Education" link="/visit-us/hands-on-education"></NavItem>
        <NavItem text="Pumpkin Tours" link="/visit-us/pumpkin-tours"></NavItem>
        {/* <NavItem text="Rental Venue" link="/visit-us/rental-venue"></NavItem> */}
        <NavItem text="Tours" link="/visit-us/tours"></NavItem>
      </NavMenu>
      <NavMenu text="For Kids">
        <NavItem text="Coloring Book" link="/for-kids/coloring-book"></NavItem>
        <NavItem text="Farming Animals" link="/for-kids/farming-animals"></NavItem>
        <NavItem text="Games" link="/for-kids/games"></NavItem>
      </NavMenu>
      <NavMenu text="News and Press">
        <NavItem text="Awards" link="/news-and-press/awards"></NavItem>
        <NavItem text="Baxter Letter" link="/news-and-press/baxter-letter"></NavItem>
        {/* <NavItem text="Blog" link="/news-and-press/blog"></NavItem> */}
        <NavItem text="News" link="/news-and-press/news"></NavItem>
        <NavItem text="Third Grade Thank Yous" link="/news-and-press/third-grade-thank-yous"></NavItem>
      </NavMenu>
      <NavMenu text="Pricing" link="/pricing">
        <NavItem text="Specials" link="/pricing/specials"></NavItem>
      </NavMenu>
      {/* <NavLink text="Photo Gallery"></NavLink> */}
      <NavLink text="Contact Us" link="/contact-us"></NavLink>
  </div>
)

export default BottomHeaderNav
