import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"

import headerStyles from "./header.module.css"

import TopHeaderNav from "../components/topHeaderNav"
import BottomHeaderNav from "../components/bottomHeaderNav"
import MobileNav from "../components/mobileNav"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "header/logo.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <header>
      <MobileNav/>
      <TopHeaderNav/>
      <div className={headerStyles.banner}>
        <a href="/"><Img alt="Baxter Barn logo" className={headerStyles.logo} fixed={data.logo.childImageSharp.fixed} /></a>
      </div>
      <BottomHeaderNav/>
    </header>
  )
}

export default Header