import React from "react"
import headerNavStyles from "./headerNav.module.css"

import NavLink from "./navLink"
import NavMenu from "./navMenu"
import NavItem from "./navItem"

const TopHeaderNav = () => (
  <div className={headerNavStyles.container} style={{paddingLeft: `270px`, paddingRight: `270px`}}>
      <NavLink text="Community" link="/community"></NavLink>
      <NavLink text="Sno Valley Horse Directory" link="/sno-valley-horse-directory"></NavLink>
      <NavMenu text="Supporters">
        <NavItem text="How to Support Us" link="/supporters/how-to-support-us"></NavItem>
        <NavItem text="Our Supporters" link="/supporters/our-supporters"></NavItem>
        <NavItem text="Support an Animal" link="/supporters/support-an-animal"></NavItem>
        {/* <NavItem text="Visitor Feedback" link="/supporters/visitor-feedback"></NavItem> */}
      </NavMenu>
  </div>
)

export default TopHeaderNav
