import PropTypes from "prop-types"
import React from "react"
import navMenuStyles from "./navMenu.module.css"

import NavLink from "./navLink"

export default class NavMenu extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className={navMenuStyles.navMenu}>
        <div className={navMenuStyles.dropdown}>
          <div className={navMenuStyles.dropdownContent}>
            {this.props.children}
          </div>
        </div>
        <NavLink text={this.props.text} link={this.props.link}></NavLink>
      </div>
    )
  }
}

NavMenu.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
};

NavMenu.defaultProps = {
  text: `Link`,
  link: `#`,
};